import React, { useState } from 'react'; // Import useState
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import axios from 'axios'; // Import axios
import './Login.css';
import logo from '../assets/logo-rise.png'; // Add the logo

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate(); // Initialize useNavigate
  const [errorMessage, setErrorMessage] = useState(''); // State for error message

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('https://staging.rise-grad.com/auth/login',
      {
        email: data.email,
        password: data.password,
        source: 'web-exam',
        role: 'customer',
      });

      console.log(response.data); // Handle success
      navigate('/dashboard'); // Redirect to /dashboard on success
    } catch (error) {
      // Handle errors
      const message = error.response && error.response.data ? error.response.data.message : 'An unexpected error occurred';
      setErrorMessage(message); // Set the error message
    }
  };

  return (
    <div className="login-page-container">
      <Link to="/" className="logo-container">
        <img src={logo} alt="RISE Exam Logo" className="login-logo" />
        <h2>RISE Exam</h2>
      </Link>
      <div className="login-container">
        <h2>Login</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>Email</label>
            <input
              type="email"
              {...register('email', { required: 'Email is required' })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              {...register('password', { required: 'Password is required' })}
            />
            {errors.password && <p>{errors.password.message}</p>}
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;