import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation for route checking
import './Navbar.css'; // Link to the CSS file for styling
import logo from '../assets/logo-rise.png';
import { HiMenuAlt3 } from 'react-icons/hi'; // Import burger menu icon
import { RiCloseLine } from 'react-icons/ri'; // Import close icon

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const location = useLocation(); // Get the current location

    useEffect(() => {
        const setNavbarColor = () => {
            if (window.scrollY > 100) {
                document.documentElement.style.setProperty('--navbar-bg-color', '#222'); // Darker
                document.documentElement.style.setProperty('--navbar-text-color', 'white');
            } else {
                document.documentElement.style.setProperty('--navbar-bg-color', 'white'); // Lighter
                document.documentElement.style.setProperty('--navbar-text-color', '#333');
            }
        };

        window.addEventListener('scroll', setNavbarColor);

        return () => {
            window.removeEventListener('scroll', setNavbarColor);
        };
    }, []);

    return (
        <nav className="navbar">
            <Link to="/" className="logo">
                <img src={logo} alt="RISE Exam Logo" className="logo-image" />
                <h1 className="logo-text">Effective<br />Language<br />Mastery</h1>
            </Link>
            <div className="menu-icon" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                {isMobileMenuOpen ? <RiCloseLine /> : <HiMenuAlt3 />}
            </div>
            <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
                <li>
                    <Link
                        to="/"
                        className={location.pathname === '/' ? 'active-home' : ''} // Add active class based on route
                    >
                        Home
                    </Link>
                </li>
                <li>
                <Link
                    to="/about-us"
                    className={location.pathname === '/about-us' ? 'active-home' : ''}
                >
                    About Us
                </Link>
                </li>
                <li>
                <Link
                    to="/faq"
                    className={location.pathname === '/faq' ? 'active-home' : ''}
                >
                    Class Category
                </Link>
                </li>
            </ul>
            <Link to="/login" className="login-button">Sign In</Link>
        </nav>
    );
};

export default Navbar;
