import React from 'react';
import { FaHome, FaClipboardList, FaSignOutAlt } from 'react-icons/fa'; // Icons for Home, Exam, and Logout
import './Dashboard.css';

const DashboardPage = () => {
  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <aside className="sidebar">
        <ul className="sidebar-menu">
          <li className="sidebar-item">
            <a href="#home" className="sidebar-link">
              <FaHome className="icon" />
              <span>Home</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a href="#exam" className="sidebar-link">
              <FaClipboardList className="icon" />
              <span>Exam</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a href="#logout" className="sidebar-link">
              <FaSignOutAlt className="icon" />
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </aside>

      {/* Main Content */}
      <main className="dashboard-content">
        <h1>Dashboard</h1>
        <p>Welcome to the RISE LMS Dashboard!</p>
        {/* Your main content goes here */}
      </main>
    </div>
  );
};

export default DashboardPage;
