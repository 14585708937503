import React from 'react';
import Navbar from '../components/Navbar'; // Assuming you have a Navbar component
import './AboutUsPage.css'; // Link to the CSS file for the About Us page

const AboutUsPage = () => {
  return (
    <div className="about-us-container">
      {/* Navbar */}
      <Navbar />

      {/* About Us Content */}
      <div className="about-us-content">
        <h1>About RISE LMS</h1>
        <p>
          RISE LMS is a comprehensive learning management system designed to provide students with the tools they need to succeed in their IELTS, TOEFL, GMAT, and GRE preparations. Our platform offers a seamless, integrated experience that supports students from the moment they start preparing until they achieve their goals.
        </p>

        <h2>Our Mission</h2>
        <p>
          At RISE LMS, our mission is to revolutionize education by providing high-quality, accessible, and personalized learning experiences. We believe in empowering students through technology and creating a supportive community where learners can thrive.
        </p>

        <h2>What We Offer</h2>
        <ul>
          <li>Online courses for IELTS, TOEFL, GMAT, and GRE</li>
          <li>Live classes with expert instructors</li>
          <li>Practice exams and simulation tests</li>
          <li>Personalized feedback and progress tracking</li>
          <li>Community support and resources</li>
        </ul>

        <h2>Contact Us</h2>
        <p>
          For more information, feel free to contact us at <a href="mailto:support@rise-grad.com">support@rise-grad.com</a> or visit our <a href="#contact">Contact Page</a>.
        </p>
      </div>
    </div>
  );
};

export default AboutUsPage;
