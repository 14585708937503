import React from 'react';
import Navbar from '../components/Navbar'; // Import the Navbar component
import './LandingPage.css'; // Link to the CSS file for landing page
import HeroImage from '../assets/Gambar Hero.png';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Navbar /> {/* Call the Navbar component */}
      <div className="hero">
        <div className="curved-background"></div>
        <div className="hero-content">
          <div className="heading-container">
            <h1>Selamat Datang di</h1>
            <h1 className="highlighted-text">RISE LMS</h1>
            <p className="description">Portal Pembelajaran Resmi & <br />Terintegrasi RISE</p>
            <button className="cta-button">Menuju ke LMS</button> {/* Added button here */}
          </div>
          <img src={HeroImage} alt="RISE Exam Logo" className="hero-image" />
        </div>
      </div>
      <div>
      </div>
    </div>
  );
};

export default LandingPage;
